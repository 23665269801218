export const seo = {
  url: 'policy',
  title: {
    en: 'Documents | Company Policy | Messages',
    es: 'Documentos | Política de empresa | Mensajes',
    ro: 'Documentos | Politica de companie | Mensajes',
  },
  desc: {
    en: 'Familiarize yourself with Omida Logistics documentation, privacy, quality and recruitment policy. Check the latest board announcements.',
    es: 'Familiarícese con la documentación, la privacidad, la calidad y la política de contratación de Omida Logistics. Consulte los últimos anuncios de la junta.',
    ro: 'Familiarizați-vă cu documentația, confidențialitatea, calitatea și politica de contractare a Omida Logistics. Consulte los últimos anuncios de la junta.',
  },
  keywords: [],
}

export const intro = {
  title: {
    en: 'Company Policies',
    es: 'Compañía de Policías',
    ro: 'Politica companiei',
  },
  desc: {
    en: 'Familiarize yourself with Omida Logistics documentation, privacy, quality and recruitment policy. Check the latest board announcements.',
    es: 'Familiarícese con la documentación, la privacidad, la calidad y la política de contratación de Omida Logistics. Consulte los últimos anuncios de la junta.',
    ro: 'Familiarizați-vă cu documentația Omida Logistics, confidențialitatea, calitatea și politica de recrutare. Verificați cele mai recente anunțuri de la bord.',
  },
  button: {
    text: {
      en: 'Explore documentation',
      es: 'Explorar documentación',
      ro: 'Explorați documentația',
    },
    action: 'SCROLL',
  },
}
